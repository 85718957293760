import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPDescription, StyledNPContent } from "./StyledCsNormapress"

const NPDescription = props => {
  return (
    <StyledNPDescription>
      <div className="description--image">
        <GatsbyImage
          image={getImage(props.data.image.localFile)}
          alt={props.data.image.altText || "Normapress"}
          title={props.data.image.title || "Normapress"}
        />
      </div>
      <div className="description--text">
        <StyledNPContent dangerouslySetInnerHTML={{__html: props.data.text}}></StyledNPContent>
      </div>

    </StyledNPDescription>
  )
}
export default NPDescription
