import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPFoto, StyledNPContent, StyledNPTitle  } from "./StyledCsNormapress"

const NPFoto = props => {
  return (
    <StyledNPFoto>
      <div className="content">
        <div className="content__title">
          <StyledNPTitle>
            {props.data.title.split('_')[0]+'_'}
            <b>{props.data.title.split('_')[1]}</b>
          </StyledNPTitle>
                  
        </div>
        <div className="content__text">
          <StyledNPContent dangerouslySetInnerHTML={{__html:props.data.text}}></StyledNPContent>
        </div>
        <GatsbyImage
          image={getImage(props.data.images.splice(0,1)[0].localFile)}
          alt={props.data.images[0].altText || "Normapress"}
          title={props.data.images[0].title || "Normapress"}
        />
      </div>
      
      <div className="images">
        {props.data.images.map(item=>{return (
          <GatsbyImage
            image={getImage(item.localFile)}
            alt={item.altText || "Normapress"}
            title={item.title || "Normapress"}
          />
        )})}

      </div>
      
    </StyledNPFoto>
  )
}
export default NPFoto
