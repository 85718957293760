import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPStationery, StyledNPContent, StyledNPTitle  } from "./StyledCsNormapress"

const NPStationery = props => {
  return (
    <StyledNPStationery>
      
      <GatsbyImage
        image={getImage(props.data.image.localFile)}
        alt={props.data.image.altText || "Normapress"}
        title={props.data.image.title || "Normapress"}
      />
      <div className="content">
        <div className="content__title">
          <StyledNPTitle>
            {props.data.title.split('_')[0]+'_'}
            <b>{props.data.title.split('_')[1]}</b>
          </StyledNPTitle>
        </div>
        <div>
          <StyledNPContent dangerouslySetInnerHTML={{__html:props.data.text}}></StyledNPContent>
        </div>
      </div>
      
    </StyledNPStationery>
  )
}
export default NPStationery
