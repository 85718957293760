import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPContent, StyledNPPoster } from "./StyledCsNormapress"

const NPPoster = props => {
  return (
    <StyledNPPoster>

      <GatsbyImage
        image={getImage(props.data.image.localFile)}
        alt={props.data.image.altText || "Normapress"}
        title={props.data.image.title || "Normapress"}
        objectFit="contain"
        objectPosition="left"
      />
      <div className="title">
        <div>
          {props.data.title}
        </div>
      </div>

      <div className="content">
        <StyledNPContent dangerouslySetInnerHTML={{__html:props.data.text}}></StyledNPContent>
      </div>
        
    </StyledNPPoster>
  )
}
export default NPPoster
