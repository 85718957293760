import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPCommunication, StyledNPContent, StyledNPSidetitle } from "./StyledCsNormapress"

const NPCommunication = props => {
  return (
    <StyledNPCommunication>
      <div className="image">
        <GatsbyImage
          image={getImage(props.data.image.localFile)}
          alt={props.data.image.altText || "Normapress"}
          title={props.data.image.title || "Normapress"}
        />

      </div>

      <div className="title">
        <StyledNPSidetitle>{props.data.title}</StyledNPSidetitle>
          
      </div>

      <div className="content">
        <StyledNPContent dangerouslySetInnerHTML={{__html:props.data.text}}></StyledNPContent>
      </div>
        
    </StyledNPCommunication>
  )
}
export default NPCommunication
