import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPGoals, StyledNPContent, StyledNPTitle} from "./StyledCsNormapress"

const NPGoals = props => {
  return (
    <StyledNPGoals>
      {props.data.map((item, ix)=>{ return (
          <div className="goal">
            <div className="goal__index">
              {'0' + (ix+1)}
            </div>
            <div className="goal__content">
              <div className="goal__content_title">
                <StyledNPTitle>
                {item.title.split('_')[0]+'_'}
                <b>{item.title.split('_')[1]}</b>
                </StyledNPTitle>
                
              </div>
              <div className="goal__content_text">
                <StyledNPContent dangerouslySetInnerHTML={{__html: item.text}}></StyledNPContent>
              </div>
            </div>
            
          </div>
      )})}
    </StyledNPGoals>
  )
}
export default NPGoals
