import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPBigidea, StyledNPContent, StyledNPTitle } from "./StyledCsNormapress"

const NPBigidea = props => {
  return (
    <StyledNPBigidea>
        <div className="title">
          <StyledNPTitle>
          {props.data.title.split('_')[0]+'_'}
          <b>{props.data.title.split('_')[1]}</b></StyledNPTitle>
        </div>
        <StyledNPContent dangerouslySetInnerHTML={{__html:props.data.text}}></StyledNPContent>
        
    </StyledNPBigidea>
  )
}
export default NPBigidea
