import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPTop } from "./StyledCsNormapress"

const NPTop = props => {
  
  return (
    <StyledNPTop css={{backgroundImage: `url(${props.data.background.localFile.childImageSharp.fixed.src})`}}>
      <GatsbyImage
        image={getImage(props.data.logo.localFile)}
        alt={props.data.logo.altText || "Normapress"}
        title={props.data.logo.title || "Normapress"}
        className="top--logo"
      />
      <h1 className="top--slogan">{props.data.slogan}</h1>
      
    </StyledNPTop>
  )
}
export default NPTop
