import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPContent, StyledNPSidetitle, StyledNPWebapp } from "./StyledCsNormapress"

const NPWebapp = props => {
  return (
    <StyledNPWebapp>

      <div className="title">
        <StyledNPSidetitle>
          {props.data.title}
        </StyledNPSidetitle>
      </div>

      <div className="content">
        <StyledNPContent dangerouslySetInnerHTML={{__html:props.data.text}}></StyledNPContent>
      </div>

      <div className="image">
        <GatsbyImage
          image={getImage(props.data.image.localFile)}
          alt={props.data.image.altText || "Normapress"}
          title={props.data.image.title || "Normapress"}
        />

      </div>
        
    </StyledNPWebapp>
  )
}
export default NPWebapp
