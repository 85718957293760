import styled from "styled-components"

let colorPrimary = "#E20613"
let colorSecondary = "#F1F1F1"
let colorThird = "#434242"

let colorText = "#808080"

export const StyledNPTitle = styled.h2`

font-family: ${props => props.theme.fonts.primary};
font-size: 1.4rem;
color: inherit;
font-weight: inherit;
margin-top: 0;
b {
    font-weight: 600;
}

@media ${props => props.theme.sizes.tabletS} {
    font-sze: 1.8rem;
}
@media ${props => props.theme.sizes.laptop} {

    font-size: 2.75rem;
}
`

export const StyledNPContent= styled.div`

font-family: ${props => props.theme.fonts.primary};
font-weight: 400;
font-size: 1rem;
line-height: 1.8rem;
color: ${colorText};

@media ${props => props.theme.sizes.laptopM} {
    font-size: 1.125rem;
}

`

export const StyledNPSidetitle = styled.h2`
font-family: ${props => props.theme.fonts.primary};
font-size: 1.4rem;
line-height: 1.8rem;
color: inherit;
font-weight: inherit;
margin-bottom: 0;
@media ${props => props.theme.sizes.laptop} {

    font-size: 3.8rem;
    line-height: 3.6rem;
    color: ${colorSecondary};
    transform: rotate(-90deg) translate(-70%,16%);
}
@media ${props => props.theme.sizes.laptop17} {
    transform: rotate(-90deg) translate(-200px,5%);
    font-size: 5.32rem;
    line-height: 5rem;
}
@media ${props => props.theme.sizes.fullHD} {

    transform: rotate(-90deg) translate(-40%,-30%);
}

`

export const StyledNPTop = styled.div`
position: relative;
min-height: calc(100vh - 94px);
background-position: center top;
background-size: cover;
.top {
    &--logo {
        position: absolute;
        top: 0;
        width: 320px;
        left: calc(50% - 160px);
        @media ${props => props.theme.sizes.tabletS} {
            width: auto;
            left: calc(50% - 270px);
        }
    }
    &--slogan {
        font-family: ${props => props.theme.fonts.primary};
        position: absolute;
        top: 35%;
        margin: 0 auto;
        font-weight: 400;
        width: 100%;
        text-align: center;
        font-size: 3rem;
        line-height: 3rem;
        color: white;

        @media ${props => props.theme.sizes.mobileL} {
            font-size: 4rem;
            line-height: 4rem;
        }

        @media ${props => props.theme.sizes.tablet} {

            font-size: 6rem;
            line-height: 6rem;
        }

        @media ${props => props.theme.sizes.laptopL} {
            font-size: 8.81rem;
            line-height: 8rem;
        }
        @media ${props => props.theme.sizes.laptop17} {
            top: 20vw;
        }
    }
}
`

export const StyledNPDescription = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
padding: 8vw 11vw;

.description {
    &--text {
        flex: 0 0 60%;
        margin: 2rem 0 0;
    }
}

@media ${props => props.theme.sizes.laptop} {
    flex-direction: row;
    .description {
        &--text {
            margin: 0 0 0 2rem;
        }
    }
}

@media ${props => props.theme.sizes.laptopM} {
    .description {
        &--text {
            flex: 0 0 50%;
        }
    }
}
`

export const StyledNPGoals = styled.div`
.goal {
    display: flex;
    flex-direction: column;
    color: black;
    padding-bottom: 5rem;

    &:nth-child(odd) {
        background-color: ${colorPrimary};
        color: white;
        .goal {
            &__index {
                padding: 5vw 10vw 0 9vw;
                text-align: right;
                &::after {
                    border-color: white;
                    left: 0;
                    right:auto;
                }
            }
            &__content {
                padding: 8% 11% 8% 11%;
                &_text>*{
                    color: white;
                }
            }
        }
    }
    &__index {
        font-family: ${props => props.theme.fonts.primary};
        text-align: left;
        font-size: 9rem;
        padding: 5vw 9vw 0 10vw;
        line-height: initial;
        height: fit-content;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            border-bottom: 3px solid black;
            width: 30vw;
            top: 58%;
            right: 0;
        }
    }
    &__content {
        padding: 8% 5% 8% 11%;
        &_title {
            margin-bottom: 2rem;
        }
        &_text {
            color: ${colorText};
        }
    }

    @media ${props => props.theme.sizes.tabletS} {
        flex-direction: row-reverse;
        &__index {
            padding: 5vw 9vw 0 0;
            &::after {
                width: 8vw;
            }
        }
        &:nth-child(odd) {
            flex-direction: row;
            &__index {
                padding: 5vw 0 0 9vw;
            }
        }

    }

    @media ${props => props.theme.sizes.laptop} {
        &__index {
            font-size: 11.12rem;
            padding: 5vw 11vw 0 0;
            &::after {
                top: 62%;
            }
        }

        &:nth-child(odd) {
            &__index {
                padding: 5vw 0 0 11vw;
            }
        }
        &__content {
            padding: 8% 11%;
        }
    }

    @media ${props => props.theme.sizes.laptopM} {
        &:nth-child(even) {
            .goal__content {
               padding-left: 20vw;
            }
        }
    }
}
`

export const StyledNPActions = styled.div`
position: relative;
min-height: 700px;
background-size: cover;
.title {
    position: absolute;

    font-family: ${props => props.theme.fonts.primary};
    color: white;
    font-size: 3.5rem;
    top: 40%;
    width: fit-content;
    right: 20vw;
    font-weight: 400;

    &::after {
        content: '';
        width: 100%;
        right: -110%;
        top: 50%;
        position: absolute;
        border-bottom: 3px solid white;
    }
    @media ${props => props.theme.sizes.mobileL} {
        font-size: 4.5rem;
    }
    @media ${props => props.theme.sizes.laptop} {
        font-size: 5.81rem;
    }
}
@media ${props => props.theme.sizes.laptop17} {
    min-height: 900px;
}
`

export const StyledNPBigidea = styled.div`
display: flex;
padding: 5rem 11vw;
flex-direction: column;

.title {
    flex: 0 0 30%;
    color: black;
    padding-right: 1rem;
}
@media ${props => props.theme.sizes.tablet} {
    padding: 8vw 11vw;
    flex-direction: row;
}
@media ${props => props.theme.sizes.laptopM} {
    padding: 8vw 20vw;
}
`

export const StyledNPWebsite = styled.div`

.top, .bottom {
    display: flex;
    &__content {
        padding: 15vw 11vw 1rem 11vw;
    }
    .gatsby-image-wrapper {
        max-width: 50%;
        margin: 0 auto;
        flex: 0 0 40%;
    }
}
.top {
    background-color: ${colorPrimary};
    flex-direction: column;
    padding-top: 5rem;
    &__content {
        &_title, &_text {
            &>* {
                color: white;
            }
        }
    }
}
.bottom {
    flex-direction: column-reverse;
    &__content {
        height: 100%;
        padding-top: 5rem;
    }
    .gatsby-image-wrapper {
        margin-top: 5rem;
        flex: 0 0 70%;
        max-width: 70%;
    }
}

@media ${props => props.theme.sizes.tabletS} {
    .top, .bottom {
        flex-direction: row;
    }
    .top {
        &__content {
            padding: 15vw 0 1rem 11vw;
        }
    }
    .bottom {
        &__content {
            padding: 20vw 11vw 1rem 20vw;
            background-color: ${colorSecondary};
        }
        .gatsby-image-wrapper {
            margin-right: -20%;
            flex: 0 0 50%;
        }
    }
}

@media ${props => props.theme.sizes.laptopM} {
    .top {
        &__content {
            padding: 15vw 0 1rem 20vw;
        }
    }
    .bottom {
        &__content {
            padding: 13rem 11vw 7vw 15vw;
        }
    }
}

@media ${props => props.theme.sizes.laptopL} {

    .bottom {
        .gatsby-image-wrapper {
            max-width: 1000px;
        }
        &__content {
            padding: 15rem 11vw 7vw 15vw;
            min-height: 550px;
        }
    }
}

`

export const StyledNPBrandbook = styled.div`
display: flex;
flex-direction: column;
.content {
    padding: 20vw 11vw 15vw 11vw;
    flex: 0 0 50%;
    &__title {
        font-size: 1.8rem;
        color: black;
        margin-bottom: 1rem;
    }
    &__text {
        font-size: 1rem;
    }
}
    .gatsby-image-wrapper {
        margin: -20% -10% -10%;
        z-index: -1;
        img {
            object-fit: contain !important;
        }
    }
@media ${props => props.theme.sizes.tabletS} {
    flex-direction: row;
    .gatsby-image-wrapper {
        margin: 0;
    }
    .content {
        padding: 20vw 0 15vw 11vw;
    }
}
@media ${props => props.theme.sizes.tabletL} {

    .gatsby-image-wrapper {
        margin: -10%;
    }
}
@media ${props => props.theme.sizes.laptopM} {
    .content {
        padding: 20vw 0 15vw 20vw;
    }
}

`

export const StyledNPStationery = styled.div`
font-family: ${props => props.theme.fonts.primary};
display: flex;
flex-direction: column-reverse;
.gatsby-image-wrapper {
    flex: 0 0 50%;
}
.content {
    height: fit-content;
    align-self: flex-end;
    padding: 4rem 11vw;
    position: relative;
    &__title {
        color: black;
    }
}

@media ${props => props.theme.sizes.tabletS} {
    flex-direction: row;
    .content {
        padding: 4rem 11vw 0 2rem;

        &::after {
            content:'';
            width: 150%;
            height: 100%;
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            background-color: ${colorSecondary};
        }
    }
}

@media ${props => props.theme.sizes.laptopM} {
    .content {
        padding: 4rem 11vw 0 4rem;
    }
}
`

export const StyledNPFoto = styled.div`
display: flex;
flex-direction: column;
padding-top: 6rem;
position: relative;
.content {
    padding: 4rem 11vw 2rem;
    flex: 0 0 50%;
    &__title {
        color: black;
    }
    &__text {
        margin-bottom: 4rem;
    }
    .gatsby-image-wrapper {
        height: 40vw;
    }
}
.images {
    padding: 0 11vw;
    .gatsby-image-wrapper {
        width: 100%;
        height: 40vw;
        &:first-child {
            margin-bottom: 2rem;
        }
    }
}
@media ${props => props.theme.sizes.laptop} {
    flex-direction: row;
    padding-top: 15rem;
    .content {
        padding: 4rem 4rem 4rem 0;
        &__title, &__text {
            padding-left: 11vw;
        }
        .gatsby-image-wrapper {
            max-width: 80%;
        }
    }
    .images {
        padding: 0;
        .gatsby-image-wrapper {
            height: 20vw;
        }
    }
    &::after {
        content: '';
        width: 94%;
        height: 80%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: ${colorSecondary};
        z-index: -1;
    }
    .content {
        flex: 0 0 55%;
        .gatsby-image-wrapper {
            display: block;
            height: 30vw;
            width: 50vw;
            max-width: 100%;
        }
    }
    .images {
        .gatsby-image-wrapper {
            height: 30vw;
        }
    }
    &::after {
        height: 70%;
    }
}
`


export const StyledNPOutdoor = styled.div`
display: flex;
flex-direction: column-reverse;
/* padding: 5rem 0; */
.gatsby-image-wrapper {
    flex: 0 0 30%;
    max-height: 50vh;
}
.content {
    height: fit-content;
    padding: 4rem 11vw 4rem 11vw;

    &__title {
        color: black;
    }
}

@media ${props => props.theme.sizes.tabletS} {
    flex-direction: row;
    .content {
        padding: 20vw 11vw 0 10vw;
    }
    .gatsby-image-wrapper {
        max-height: 100%;
    }
}

@media ${props => props.theme.sizes.laptop} {
    .gatsby-image-wrapper {
        flex: 0 0 40%;
    }
}
`

export const StyledNPMaterials = styled.div`
display: flex;
flex-direction: column;
.gatsby-image-wrapper {
    margin: 5vw 0 0;
    max-width: 1292px;
    align-self: center;
}
.content {
    padding: 0 11vw;
    &__title {
        color: black;
    }
}
@media ${props => props.theme.sizes.tabletS} {

    .content {
        padding-left: 20vw;
    }
    .gatsby-image-wrapper {
        margin: 5vw 11vw 0;
    }
}

`

export const StyledNPCommunication = styled.div`
display: flex;
flex-wrap: wrap;
padding: 5rem 0;
color: black;
align-items: center;
.image {
    flex: 0 0 40%;
    order: 3;
    display: flex;
    justify-self: center;
    justify-content: center;
    margin: 0 auto;

    .gatsby-image-wrapper {
        width: 100%;
        position: relative;
        overflow: visible;
        &::after {
            position: absolute;
            z-index: -1;
            content: '';
            right: 50px;
            width: 1000px;
            height: 70%;
            top: 15%;
            background-color: ${colorSecondary};
        }
    }
}
.title {
    flex: 0 0 100%;
    order: 1;
    padding: 0 11vw 1rem;
}
.content {
    margin: 0 11vw 5vw;
    height: fit-content;
    order: 2;
}

@media ${props => props.theme.sizes.laptop} {
    flex-wrap: nowrap;
    padding: 15rem 0 6rem;
    .title {
        padding: 0;
        order: 0;
        max-width: 0;
        min-width: 120px;
        z-index: -1;
    }
    .content {
        order: 0;
        margin: 0 11vw 0 0;
        &>div {
            background-color: white;
            padding: 1rem 0 1rem 1rem;
        }
    }
    .image {
        order: 0;
        flex: 0 0 30%;
        .gatsby-image-wrapper {
            width: auto;
        }
    }
}

@media ${props => props.theme.sizes.laptopM} {
    .image {
        flex: 0 0 50%;
        margin: 0;
    }
    .content {
        margin-right: 11vw;
    }
}
`

export const StyledNPWebapp = styled.div`
color: black;
display: flex;
margin-top: 10vw;
flex-direction: column;
position: relative;

.title {
    font-family: ${props => props.theme.fonts.primary};
    padding: 0 11vw 2rem;
    div {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
}

.content {
    padding: 0 11vw;
    max-width: 60%;
    margin: -1rem auto 0 -1rem;
}

.image {
    margin-top: 2rem;
    width: 90%;
    align-self: flex-end;
}

@media ${props => props.theme.sizes.tablet} {

    .image {
        /* width: 60%; */
        display: flex;
        justify-content: flex-end;
    }
}

@media ${props => props.theme.sizes.laptop} {
    flex-direction: row;
    padding: 0;
    min-height: 670px;
    .title {
        max-width: 0;
        min-width: 8rem;
        padding: 0 0 0 2rem;
        z-index: -1;
        h2 {
            color: ${colorSecondary};
            transform: rotate(-90deg) translate(-8rem,-7rem);
            width: 550px;
            text-align: right;
        }
    }
    .content {
        padding: 0 11% 0 0;
        &>div {
            background-color: white;
            padding: 1rem 0 1rem 1rem;
        }
    }
    .image {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        /* .gatsby-image-wrapper {
            width: 65vw;
        } */
    }

    min-height: 600px;
    justify-content: space-between;
    .title {
        min-width: 16rem;
        div {
            transform: rotate(-90deg) translate(-12rem,-10rem);
            font-size: 3rem;
            line-height: 3rem;
            width: 500px;
        }
    }


    .title {
        div {
            transform: rotate(-90deg) translate(-14rem,-9rem);
            font-size: 3.5rem;
            line-height: 3.3rem;
            width: 600px;
        }
    }
}

@media ${props => props.theme.sizes.laptopM} {

min-height: 800px;
    .title {
        div {
            transform: rotate(-90deg) translate(-200px,-15rem);
            font-size: 5.32rem;
            line-height: 5rem;
            width: 800px;
        }
    }
    .content {
        &>* {
            display: inline-block;
            max-width: 66%;
        }
    }

}
@media ${props => props.theme.sizes.fullHD} {
min-height: 900px;
}
`

export const StyledNPPoster = styled.div`
display: flex;
align-items: center;
background-color: ${colorSecondary};
min-height: 520px;
flex-wrap: wrap;
padding: 5rem 11vw;
.gatsby-image-wrapper {
    order: 3;
    width: 320px;
    margin: 0 auto;
    position: relative;
    z-index: 9;
    &::after {
        z-index: 10;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: inset 0px 0px 30px 30px #f1f1f1;
        box-shadow: inset 0px 0px 30px 30px #f1f1f1;
    }
}
.title {
    font-family: ${props => props.theme.fonts.primary};
    padding-bottom: 1rem;
    color: black;
    text-transform: uppercase;
    div {
        font-size: 2rem;
        line-height: 2rem;
    }
}
.content {
    padding: 0 0 4rem 0;
}
@media ${props => props.theme.sizes.laptop} {
    padding: 5rem 0 0 0;
    flex-wrap: nowrap;

    .gatsby-image-wrapper {
        order: 0;
        margin-bottom: -100px;
        flex: 0 0 40%;
        &::after {
            display: none;
        }
    }
    .title {
        max-width: 8%;
        min-width: 15rem;
        padding-bottom: 0;
        div {
            transform: rotate(-90deg) translate(-4rem,-8rem);
            font-size: 4rem;
            line-height: 4rem;
            color: white;
            width: 550px;
            text-align: right;
        }
    }

    .content {
        align-self: flex-end;
        padding: 0 11vw 4rem 0;
    }
}

@media ${props => props.theme.sizes.laptopM} {
    min-height: 600px;
    padding: 0;
    .title {
        div {
            transform: rotate(-90deg) translate(0,-7rem);
            font-size: 5.32rem;
            line-height: 5rem;
        }
    }
    .content {
        padding: 0 20vw 8rem 0;
    }
}
`

export const StyledNPEffects = styled.div`
background-color: ${colorThird};
display: flex;
flex-direction: column;
padding: 5rem 11vw;
.content {
    flex: 0 0 45%;
    margin-right: 5%;
    margin-bottom: 5%;
    &, &__text >* {
        color: white;
    }
}
.gatsby-image-wrapper {
    flex: 0 0 50%;
}

@media ${props => props.theme.sizes.tabletL} {
    flex-direction: row;
    padding: 11vw 7vw;
    .content {
        margin-bottom: 0;
    }
}
`