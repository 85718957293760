import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPWebsite, StyledNPContent, StyledNPTitle } from "./StyledCsNormapress"

const NPWebsite = props => {
  return (
    <StyledNPWebsite>
      <div className="top">
        <div className="top__content">
          
          <div className="top__content_title">
              <StyledNPTitle>
                {props.data.title.split('_')[0]+'_'}
                <b>{props.data.title.split('_')[1]}</b>
              </StyledNPTitle>
          </div>
              
          <div className="top__content_text">
            <StyledNPContent dangerouslySetInnerHTML={{__html:props.data.text}}></StyledNPContent>
          </div>
        </div>
        <GatsbyImage
            image={getImage(props.data.image.localFile)}
            alt={props.data.image.altText || "Normapress"}
            title={props.data.image.title || "Normapress"}
          />
      </div>
      <div className="bottom">
        <GatsbyImage
          image={getImage(props.data.secimage.localFile)}
          alt={props.data.secimage.altText || "Normapress"}
          title={props.data.secimage.title || "Normapress"}
        />
        <div className="bottom__content">
          <div className="bottom__content_text">
            <StyledNPContent dangerouslySetInnerHTML={{__html:props.data.sectext}}></StyledNPContent>
          </div>
        </div>
      </div>
        
    </StyledNPWebsite>
  )
}
export default NPWebsite
