import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { StyledNPActions } from "./StyledCsNormapress"

const NPActions = props => {
  return (
    <StyledNPActions css={{backgroundImage: `url(${props.data.background.localFile.childImageSharp.fixed.src})`}}>
      
        <h2 className="title">
          {props.data.title}
        </h2>
        
    </StyledNPActions>
  )
}
export default NPActions
