import React from "react"
import Layout from "../../components/layout"

import type { HeadFC, PageProps } from "gatsby"
import { SEO } from "../../components/seo"

import NPTop from "../../components/organisms/CsNormapress/NPTop"
import NPDescription from "../../components/organisms/CsNormapress/NPDescription"
import NPGoals from "../../components/organisms/CsNormapress/NPGoals"
import NPActions from "../../components/organisms/CsNormapress/NPActions"
import NPBigidea from "../../components/organisms/CsNormapress/NPBigidea"
import NPWebsite from "../../components/organisms/CsNormapress/NPWebsite"
import NPBrandbook from "../../components/organisms/CsNormapress/NPBrandbook"
import NPStationery from "../../components/organisms/CsNormapress/NPStationery"
import NPFoto from "../../components/organisms/CsNormapress/NPFoto"
import NPOutdoor from "../../components/organisms/CsNormapress/NPOutdoor"
import NPMaterials from "../../components/organisms/CsNormapress/NPMaterials"
import NPCommunication from "../../components/organisms/CsNormapress/NPCommunication"
import NPWebapp from "../../components/organisms/CsNormapress/NPWebapp"
import NPPoster from "../../components/organisms/CsNormapress/NPPoster"
import NPEffects from "../../components/organisms/CsNormapress/NPEffects"

import NextItem from "../../components/molecules/NextItem"
import PreviousItem from "../../components/molecules/PreviousItem"
import NextPreview from "../../components/atoms/NextPreview"


export default function CaseStudy(props) {
  const ACF = props.pageContext.normapress
  const SEO = props.pageContext.seo



  return <Layout seoData={SEO} seoUrl={props.pageContext.slug}>
      <div style={{ overflowX: 'hidden'}}>
      <NPTop data={ACF.npTop} />
      <NPDescription data={ACF.npDescription} />
      <NPGoals data={ACF.npGoals} />
      <NPActions data={ACF.npActions} />
      <NPBigidea data={ACF.npBigidea} />
      <NPWebsite data={ACF.npWebsite} />
      <NPBrandbook data={ACF.npBrandbook} />
      <NPStationery data={ACF.npStationery} />
      <NPFoto data={ACF.npFoto} />
      <NPOutdoor data={ACF.npOutdoor} />
      <NPMaterials data={ACF.npMaterials} />
      <NPCommunication data={ACF.npCommunication} />
      <NPWebapp data={ACF.npWebapp} />
      <NPPoster data={ACF.npPoster} />
      <NPEffects data={ACF.npEffects} />

      <NextPreview>
        {props.pageContext.previous && (
          <PreviousItem
            prevLink={props.pageContext.previous.uri}
            prevName="Poprzednie Case Study"
          />
        )}
        {props.pageContext.next && (
          <NextItem
            nextLink={props.pageContext.next.uri}
            nextName="Następne Case Study"
          />
        )}
      </NextPreview>
      </div>
    </Layout>
}
export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
